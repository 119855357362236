import React from "react"
import styled from 'styled-components';
import Seo from "../components/seoComponent";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../layout/layout"
import { useTranslation } from "react-i18next"

const SectionWithBackgroundImage = styled.section`
    display: grid;
    background: var(--darkPurple);
    
    .content, .background {
        display: grid;
        grid-area: 1/1;
    }
    
    color: white;
    margin: 0 auto;
    min-height: calc(100vh - 168px);
    place-items: center;
    text-align: center;
`

const NotFoundPage = () => {

    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title={`404: ${t('navigation.404')}`} />
            <SectionWithBackgroundImage>
                <div className="background">
                    <StaticImage
                        src="../../static/wQ1.jpg"
                        alt="404 cat"
                        layout="fullWidth"
                        aspectRatio={3 / 4}
                    />
                </div>
                <div className="content">
                    <h1>
                        {t('utils.oops')}{' '}
                        {t('navigation.404')}
                        <span role="img" aria-label="emoji"> 🤭</span>
                    </h1>
                </div>
            </SectionWithBackgroundImage>
        </Layout>
    )
}

export default NotFoundPage
